import React from 'react';

export default function NasaLogoColor() {
  return (
    <svg
      role='img'
      aria-label='NASA Logo'
      width='44'
      height='44'
      viewBox='0 0 44 44'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='NASA_logo'>
        <path
          id='Vector'
          d='M20.3614 39.939C30.26 39.939 38.2843 31.9146 38.2843 22.016C38.2843 12.1174 30.26 4.09303 20.3614 4.09303C10.4628 4.09303 2.43839 12.1174 2.43839 22.016C2.43839 31.9146 10.4628 39.939 20.3614 39.939Z'
          fill='#0C448C'
        />
        <g id='Group'>
          <path
            id='Vector_2'
            d='M19.3031 7.73658C19.4141 7.73658 19.5041 7.64662 19.5041 7.53565C19.5041 7.42468 19.4141 7.33472 19.3031 7.33472C19.1922 7.33472 19.1022 7.42468 19.1022 7.53565C19.1022 7.64662 19.1922 7.73658 19.3031 7.73658Z'
            fill='white'
          />
          <path
            id='Vector_3'
            d='M21.366 8.00449C21.477 8.00449 21.567 7.91453 21.567 7.80356C21.567 7.69259 21.477 7.60263 21.366 7.60263C21.2551 7.60263 21.1651 7.69259 21.1651 7.80356C21.1651 7.91453 21.2551 8.00449 21.366 8.00449Z'
            fill='white'
          />
          <path
            id='Vector_4'
            d='M24.0719 11.648C24.1829 11.648 24.2728 11.5581 24.2728 11.4471C24.2728 11.3361 24.1829 11.2462 24.0719 11.2462C23.9609 11.2462 23.871 11.3361 23.871 11.4471C23.871 11.5581 23.9609 11.648 24.0719 11.648Z'
            fill='white'
          />
          <path
            id='Vector_5'
            d='M9.22985 30.4015C9.34082 30.4015 9.43078 30.3116 9.43078 30.2006C9.43078 30.0896 9.34082 29.9997 9.22985 29.9997C9.11888 29.9997 9.02892 30.0896 9.02892 30.2006C9.02892 30.3116 9.11888 30.4015 9.22985 30.4015Z'
            fill='white'
          />
          <path
            id='Vector_6'
            d='M9.75226 32.009C9.86323 32.009 9.95319 31.919 9.95319 31.808C9.95319 31.6971 9.86323 31.6071 9.75226 31.6071C9.64129 31.6071 9.55133 31.6971 9.55133 31.808C9.55133 31.919 9.64129 32.009 9.75226 32.009Z'
            fill='white'
          />
          <path
            id='Vector_7'
            d='M7.26072 30.5922C7.34383 30.5922 7.4112 30.5248 7.4112 30.4417C7.4112 30.3586 7.34383 30.2912 7.26072 30.2912C7.17762 30.2912 7.11025 30.3586 7.11025 30.4417C7.11025 30.5248 7.17762 30.5922 7.26072 30.5922Z'
            fill='white'
          />
          <path
            id='Vector_8'
            d='M15.4319 33.7504C15.5152 33.7504 15.5828 33.6828 15.5828 33.5995C15.5828 33.5161 15.5152 33.4485 15.4319 33.4485C15.3485 33.4485 15.281 33.5161 15.281 33.5995C15.281 33.6828 15.3485 33.7504 15.4319 33.7504Z'
            fill='white'
          />
          <path
            id='Vector_9'
            d='M28.2477 31.0878C28.3308 31.0878 28.3981 31.0204 28.3981 30.9373C28.3981 30.8542 28.3308 30.7869 28.2477 30.7869C28.1645 30.7869 28.0972 30.8542 28.0972 30.9373C28.0972 31.0204 28.1645 31.0878 28.2477 31.0878Z'
            fill='white'
          />
          <path
            id='Vector_10'
            d='M31.972 27.0429C32.0554 27.0429 32.1229 26.9753 32.1229 26.8919C32.1229 26.8086 32.0554 26.741 31.972 26.741C31.8887 26.741 31.8211 26.8086 31.8211 26.8919C31.8211 26.9753 31.8887 27.0429 31.972 27.0429Z'
            fill='white'
          />
          <path
            id='Vector_11'
            d='M13.7173 12.7728C13.8004 12.7728 13.8678 12.7054 13.8678 12.6223C13.8678 12.5392 13.8004 12.4718 13.7173 12.4718C13.6342 12.4718 13.5668 12.5392 13.5668 12.6223C13.5668 12.7054 13.6342 12.7728 13.7173 12.7728Z'
            fill='white'
          />
          <path
            id='Vector_12'
            d='M17.8163 9.76241C17.8994 9.76241 17.9667 9.69504 17.9667 9.61193C17.9667 9.52883 17.8994 9.46146 17.8163 9.46146C17.7332 9.46146 17.6658 9.52883 17.6658 9.61193C17.6658 9.69504 17.7332 9.76241 17.8163 9.76241Z'
            fill='white'
          />
          <path
            id='Vector_13'
            d='M17.3711 9.46771C17.4542 9.46771 17.5216 9.40034 17.5216 9.31723C17.5216 9.23413 17.4542 9.16676 17.3711 9.16676C17.288 9.16676 17.2206 9.23413 17.2206 9.31723C17.2206 9.40034 17.288 9.46771 17.3711 9.46771Z'
            fill='white'
          />
          <path
            id='Vector_14'
            d='M16.9692 9.17301C17.0523 9.17301 17.1197 9.10564 17.1197 9.02254C17.1197 8.93943 17.0523 8.87206 16.9692 8.87206C16.8861 8.87206 16.8187 8.93943 16.8187 9.02254C16.8187 9.10564 16.8861 9.17301 16.9692 9.17301Z'
            fill='white'
          />
          <path
            id='Vector_15'
            d='M14.3603 13.5502C14.4434 13.5502 14.5107 13.4828 14.5107 13.3997C14.5107 13.3166 14.4434 13.2492 14.3603 13.2492C14.2772 13.2492 14.2098 13.3166 14.2098 13.3997C14.2098 13.4828 14.2772 13.5502 14.3603 13.5502Z'
            fill='white'
          />
          <path
            id='Vector_16'
            d='M20.398 5.71701C20.4811 5.71701 20.5485 5.64964 20.5485 5.56654C20.5485 5.48343 20.4811 5.41606 20.398 5.41606C20.3149 5.41606 20.2475 5.48343 20.2475 5.56654C20.2475 5.64964 20.3149 5.71701 20.398 5.71701Z'
            fill='white'
          />
          <path
            id='Vector_17'
            d='M21.3897 10.0571C21.4728 10.0571 21.5402 9.98973 21.5402 9.90663C21.5402 9.82352 21.4728 9.75615 21.3897 9.75615C21.3066 9.75615 21.2392 9.82352 21.2392 9.90663C21.2392 9.98973 21.3066 10.0571 21.3897 10.0571Z'
            fill='white'
          />
          <path
            id='Vector_18'
            d='M19.8256 9.20962C19.9087 9.20962 19.976 9.14225 19.976 9.05915C19.976 8.97604 19.9087 8.90867 19.8256 8.90867C19.7425 8.90867 19.6751 8.97604 19.6751 9.05915C19.6751 9.14225 19.7425 9.20962 19.8256 9.20962Z'
            fill='white'
          />
          <path
            id='Vector_19'
            d='M17.1465 10.3621C17.2296 10.3621 17.297 10.2947 17.297 10.2116C17.297 10.1285 17.2296 10.0611 17.1465 10.0611C17.0634 10.0611 16.996 10.1285 16.996 10.2116C16.996 10.2947 17.0634 10.3621 17.1465 10.3621Z'
            fill='white'
          />
          <path
            id='Vector_20'
            d='M28.9612 35.0631C29.0443 35.0631 29.1116 34.9957 29.1116 34.9126C29.1116 34.8295 29.0443 34.7622 28.9612 34.7622C28.8781 34.7622 28.8107 34.8295 28.8107 34.9126C28.8107 34.9957 28.8781 35.0631 28.9612 35.0631Z'
            fill='white'
          />
          <path
            id='Vector_21'
            d='M8.97534 31.3696C9.05869 31.3696 9.12626 31.302 9.12626 31.2186C9.12626 31.1353 9.05869 31.0677 8.97534 31.0677C8.89199 31.0677 8.82442 31.1353 8.82442 31.2186C8.82442 31.302 8.89199 31.3696 8.97534 31.3696Z'
            fill='white'
          />
          <path
            id='Vector_22'
            d='M19.4505 33.3119C19.5338 33.3119 19.6014 33.2443 19.6014 33.161C19.6014 33.0776 19.5338 33.01 19.4505 33.01C19.3671 33.01 19.2996 33.0776 19.2996 33.161C19.2996 33.2443 19.3671 33.3119 19.4505 33.3119Z'
            fill='white'
          />
          <path
            id='Vector_23'
            d='M12.0929 31.249C12.1762 31.249 12.2438 31.1814 12.2438 31.0981C12.2438 31.0147 12.1762 30.9472 12.0929 30.9472C12.0095 30.9472 11.942 31.0147 11.942 31.0981C11.942 31.1814 12.0095 31.249 12.0929 31.249Z'
            fill='white'
          />
          <path
            id='Vector_24'
            d='M13.3958 33.3757C13.4792 33.3757 13.5467 33.3082 13.5467 33.2248C13.5467 33.1415 13.4792 33.0739 13.3958 33.0739C13.3124 33.0739 13.2449 33.1415 13.2449 33.2248C13.2449 33.3082 13.3124 33.3757 13.3958 33.3757Z'
            fill='white'
          />
          <path
            id='Vector_25'
            d='M22.9869 32.0357C23.07 32.0357 23.1373 31.9684 23.1373 31.8853C23.1373 31.8022 23.07 31.7348 22.9869 31.7348C22.9037 31.7348 22.8364 31.8022 22.8364 31.8853C22.8364 31.9684 22.9037 32.0357 22.9869 32.0357Z'
            fill='white'
          />
          <path
            id='Vector_26'
            d='M28.2744 29.0517C28.3575 29.0517 28.4249 28.9844 28.4249 28.9013C28.4249 28.8181 28.3575 28.7508 28.2744 28.7508C28.1913 28.7508 28.124 28.8181 28.124 28.9013C28.124 28.9844 28.1913 29.0517 28.2744 29.0517Z'
            fill='white'
          />
          <path
            id='Vector_27'
            d='M32.2833 30.8833C32.3664 30.8833 32.4337 30.816 32.4337 30.7329C32.4337 30.6498 32.3664 30.5824 32.2833 30.5824C32.2002 30.5824 32.1328 30.6498 32.1328 30.7329C32.1328 30.816 32.2002 30.8833 32.2833 30.8833Z'
            fill='white'
          />
          <path
            id='Vector_28'
            d='M33.6495 31.7576C33.7326 31.7576 33.8 31.6902 33.8 31.6071C33.8 31.524 33.7326 31.4566 33.6495 31.4566C33.5664 31.4566 33.4991 31.524 33.4991 31.6071C33.4991 31.6902 33.5664 31.7576 33.6495 31.7576Z'
            fill='white'
          />
          <path
            id='Vector_29'
            d='M33.8742 29.3732C33.9573 29.3732 34.0246 29.3058 34.0246 29.2227C34.0246 29.1396 33.9573 29.0723 33.8742 29.0723C33.7911 29.0723 33.7237 29.1396 33.7237 29.2227C33.7237 29.3058 33.7911 29.3732 33.8742 29.3732Z'
            fill='white'
          />
          <path
            id='Vector_30'
            d='M13.9414 25.9976C14.0245 25.9976 14.0919 25.9302 14.0919 25.8471C14.0919 25.764 14.0245 25.6966 13.9414 25.6966C13.8583 25.6966 13.791 25.764 13.791 25.8471C13.791 25.9302 13.8583 25.9976 13.9414 25.9976Z'
            fill='white'
          />
          <path
            id='Vector_31'
            d='M13.9986 29.504C14.1096 29.504 14.1995 29.4141 14.1995 29.3031C14.1995 29.1921 14.1096 29.1022 13.9986 29.1022C13.8876 29.1022 13.7977 29.1921 13.7977 29.3031C13.7977 29.4141 13.8876 29.504 13.9986 29.504Z'
            fill='white'
          />
          <path
            id='Vector_32'
            d='M15.2042 32.0491C15.3151 32.0491 15.4051 31.9592 15.4051 31.8482C15.4051 31.7372 15.3151 31.6473 15.2042 31.6473C15.0932 31.6473 15.0032 31.7372 15.0032 31.8482C15.0032 31.9592 15.0932 32.0491 15.2042 32.0491Z'
            fill='white'
          />
          <path
            id='Vector_33'
            d='M29.6443 29.3969C29.7553 29.3969 29.8453 29.3069 29.8453 29.1959C29.8453 29.085 29.7553 28.995 29.6443 28.995C29.5334 28.995 29.4434 29.085 29.4434 29.1959C29.4434 29.3069 29.5334 29.3969 29.6443 29.3969Z'
            fill='white'
          />
          <path
            id='Vector_34'
            d='M34.8418 29.1022C34.9527 29.1022 35.0427 29.0122 35.0427 28.9012C35.0427 28.7903 34.9527 28.7003 34.8418 28.7003C34.7308 28.7003 34.6408 28.7903 34.6408 28.9012C34.6408 29.0122 34.7308 29.1022 34.8418 29.1022Z'
            fill='white'
          />
          <path
            id='Vector_35'
            d='M32.2698 28.0037C32.3808 28.0037 32.4708 27.9138 32.4708 27.8028C32.4708 27.6918 32.3808 27.6019 32.2698 27.6019C32.1589 27.6019 32.0689 27.6918 32.0689 27.8028C32.0689 27.9138 32.1589 28.0037 32.2698 28.0037Z'
            fill='white'
          />
          <path
            id='Vector_36'
            d='M29.6443 28.1109C29.7553 28.1109 29.8453 28.0209 29.8453 27.91C29.8453 27.799 29.7553 27.709 29.6443 27.709C29.5334 27.709 29.4434 27.799 29.4434 27.91C29.4434 28.0209 29.5334 28.1109 29.6443 28.1109Z'
            fill='white'
          />
          <path
            id='Vector_37'
            d='M24.2286 7.40973L24.7282 7.36196L24.2295 7.33383C24.2139 7.23738 24.1366 7.16192 24.0393 7.14942L23.9915 6.652L23.9629 7.15344C23.8727 7.17353 23.8035 7.24899 23.7924 7.34276L23.2815 7.38919L23.7959 7.41688C23.8147 7.50306 23.8843 7.57048 23.9719 7.58655L24.0183 8.09915L24.0455 7.58745C24.138 7.57182 24.2112 7.50082 24.2286 7.40973Z'
            fill='white'
          />
          <path
            id='Vector_38'
            d='M15.7958 12.9724L16.2954 12.9246L15.7967 12.8965C15.7815 12.8 15.7038 12.7246 15.6065 12.7121L15.5587 12.2147L15.5306 12.7161C15.4399 12.7362 15.3707 12.8116 15.3595 12.9054L14.8487 12.9518L15.3631 12.9795C15.3819 13.0657 15.4515 13.1331 15.539 13.1492L15.5855 13.6618L15.6132 13.1501C15.7056 13.1349 15.7788 13.0635 15.7958 12.9724Z'
            fill='white'
          />
          <path
            id='Vector_39'
            d='M34.4756 27.6796L34.9753 27.6318L34.4765 27.6037C34.4613 27.5072 34.3836 27.4318 34.2863 27.4193L34.2385 26.9219L34.2104 27.4233C34.1202 27.4434 34.051 27.5188 34.0398 27.6126L33.529 27.6591L34.0434 27.6867C34.0621 27.7729 34.1318 27.8403 34.2193 27.8564L34.2658 28.369L34.2934 27.8573C34.385 27.8421 34.4582 27.7711 34.4756 27.6796Z'
            fill='white'
          />
          <path
            id='Vector_40'
            d='M14.108 35.3694L14.6076 35.3216L14.1089 35.2935C14.0933 35.197 14.016 35.1216 13.9187 35.1091L13.8709 34.6121L13.8428 35.1131C13.7521 35.1332 13.6829 35.2086 13.6717 35.3024L13.1609 35.3489L13.6753 35.3765C13.6941 35.4627 13.7637 35.5306 13.8512 35.5462L13.8977 36.0588L13.9254 35.5471C14.0173 35.5319 14.091 35.4605 14.108 35.3694Z'
            fill='white'
          />
          <path
            id='Vector_41'
            d='M32.8883 31.6187L33.3879 31.571L32.8892 31.5428C32.874 31.4464 32.7963 31.3709 32.699 31.3584L32.6512 30.861L32.623 31.362C32.5329 31.3821 32.4636 31.4575 32.4525 31.5513L31.9417 31.5978L32.4561 31.6254C32.4748 31.7116 32.5445 31.779 32.632 31.7951L32.6784 32.3077L32.7061 31.796C32.7976 31.7813 32.8713 31.7098 32.8883 31.6187Z'
            fill='white'
          />
          <path
            id='Vector_42'
            d='M24.6117 17.7228C22.5278 18.5328 20.4953 19.2557 18.7803 19.7924C15.2953 20.8837 5.76 23.8378 0 27.5617L0.482232 27.7492C3.99181 25.7667 6.27304 25.1438 8.46586 24.454C10.8502 23.7038 18.5584 21.8999 22.0487 20.9176C23.228 20.5859 24.609 20.1514 26.0718 19.6227C25.7316 19.1481 25.3726 18.6721 24.9948 18.197C24.8685 18.0367 24.7403 17.8787 24.6117 17.7228ZM27.1577 21.232C26.6532 21.5726 26.2303 21.8446 25.9343 22.016C24.2197 23.0073 15.8605 28.7137 13.9588 30.0265C12.0571 31.3392 6.77894 36.3223 5.22508 37.769L5.1715 38.3579C10.1814 33.8571 11.7531 32.7471 13.7445 31.232C16.2093 29.3567 21.308 25.8873 23.0409 24.7755C24.6974 23.7128 26.1915 22.7354 27.5323 21.841C27.5105 21.8044 27.4886 21.7678 27.4667 21.7307C27.3658 21.5651 27.2627 21.399 27.1577 21.232ZM34.9891 12.9608C32.5485 14.3914 28.7018 16.1181 24.9698 17.5831C25.0591 17.6925 25.148 17.8027 25.2364 17.9139C25.6534 18.439 26.0468 18.9677 26.417 19.4968C29.3505 18.4163 32.5694 16.966 35.1235 15.2107C33.9067 16.3636 29.8841 19.37 27.4314 21.0458C27.5578 21.2516 27.6801 21.4574 27.7985 21.6624C31.5143 19.1758 34.0148 17.3455 35.4718 16.1216C37.4811 14.4338 42.0623 10.6563 43.8037 5.59288C40.7491 9.47798 37.1377 11.7012 34.9891 12.9608Z'
            fill='#E54D2F'
          />
          <path
            id='Vector_43'
            d='M18.0551 26.4539C17.66 25.9565 17.1134 25.2903 16.7031 24.7737C16.1543 24.0829 15.6467 23.385 15.1814 22.688C15.0296 22.726 14.8782 22.7639 14.7268 22.8019C15.2886 23.6784 15.9123 24.5536 16.5919 25.4091C17.0884 26.0342 17.5399 26.5374 17.7712 26.8504C17.818 26.9138 17.9109 27.054 18.0364 27.2407C18.1811 27.1424 18.3271 27.0433 18.4744 26.9433C18.332 26.7884 18.1913 26.6254 18.0551 26.4539ZM20.9396 29.0723C20.615 28.7651 19.8278 28.252 19.0084 27.4822C18.8276 27.6064 18.645 27.7323 18.4619 27.8591C18.991 28.6048 19.7117 29.5206 20.2761 29.7876C21.1262 30.1903 21.3553 29.4661 20.9396 29.0723ZM25.2364 17.9139C20.5516 12.0173 14.8286 8.77785 12.3612 10.7385C10.4448 12.2611 11.2315 16.711 13.8731 21.3856C14.0097 21.3387 14.145 21.2927 14.279 21.2471C11.6129 16.6927 10.8833 12.4602 12.6907 11.0238C14.9621 9.219 20.4467 12.4709 24.9957 18.1966C25.9374 19.3816 26.7652 20.5711 27.4663 21.7307C30.1507 26.1735 30.9745 30.1912 29.1197 31.8313C28.5522 32.3331 26.8179 32.5301 24.5461 31.5075C24.5144 31.5473 24.5653 31.6549 24.605 31.6812C26.5661 32.4698 28.4446 32.7703 29.393 32.017C31.6719 30.206 29.9212 23.8106 25.2364 17.9139Z'
            fill='white'
          />
          <path
            id='Vector_44'
            d='M5.14426 18.6136H8.14482L10.6631 22.8867C10.6631 22.8867 10.6631 19.7924 10.6631 19.5513C10.6631 19.1762 10.1876 18.6873 10.0202 18.6136C10.2211 18.6136 11.9759 18.6136 12.0964 18.6136C11.9692 18.6471 11.5606 19.1427 11.5606 19.5513C11.5606 19.7522 11.5606 24.2397 11.5606 24.454C11.5606 24.7554 11.996 25.1706 12.2036 25.3314H9.31021L6.7517 21.0382C6.7517 21.0382 6.7517 24.2397 6.7517 24.4138C6.7517 24.7487 7.07989 25.0702 7.42147 25.3448H5.21124C5.52603 25.2108 5.82742 24.7889 5.85421 24.454C5.881 24.1191 5.85421 19.7991 5.85421 19.6116C5.85421 19.203 5.45905 18.8681 5.14426 18.6136Z'
            fill='white'
          />
          <path
            id='Vector_45'
            d='M32.5913 25.3314H36.5831C36.3621 25.2778 36.1143 25.0166 35.9804 24.6683C35.8464 24.32 33.7299 18.6136 33.7299 18.6136H32.2122C31.7818 18.9235 31.3206 19.2503 30.8281 19.5946C30.8267 19.6071 30.8254 19.6196 30.8231 19.6317C30.7571 20.0335 29.5693 24.3352 29.497 24.5478C29.3965 24.8425 28.8808 25.1706 28.6732 25.3448H30.8231C30.6088 25.1037 30.4347 24.8692 30.4749 24.6013C30.515 24.3334 30.6356 23.7976 30.6356 23.7976H32.8592C32.9597 24.0655 33.0347 24.3062 33.0736 24.5344C33.1204 24.809 32.8391 25.1171 32.5913 25.3314ZM30.8365 22.9403L31.5867 20.489L32.5779 22.9403H30.8365ZM30.4985 18.8583C30.6088 18.777 30.7196 18.6953 30.8298 18.6136H30.2471C30.3052 18.6672 30.4012 18.7516 30.4985 18.8583Z'
            fill='white'
          />
          <path
            id='Vector_46'
            d='M15.231 24.6013C15.2711 24.3334 15.3917 23.7976 15.3917 23.7976H17.6153C17.7158 24.0655 17.7908 24.3062 17.8297 24.5344C17.8765 24.809 17.5952 25.1171 17.3474 25.3314H20.8619C20.9351 25.2823 21.0079 25.2332 21.0807 25.1845C20.9445 25.0639 20.819 24.8831 20.7364 24.6683C20.6025 24.32 18.486 18.6136 18.486 18.6136H15.0032C15.1707 18.7677 15.6529 19.1829 15.5792 19.6317C15.5136 20.0335 14.3254 24.3352 14.2531 24.5478C14.1526 24.8425 13.6369 25.1706 13.4293 25.3448H15.5792C15.3649 25.1037 15.1908 24.8692 15.231 24.6013ZM16.3428 20.489L17.334 22.9403H15.5926L16.3428 20.489Z'
            fill='white'
          />
          <path
            id='Vector_47'
            d='M27.3712 21.9482C26.7157 22.3848 26.024 22.8407 25.2953 23.3167C25.6222 23.4752 25.8825 23.6744 25.9075 23.9316C25.9539 24.4147 24.9832 24.6482 24.1058 24.5477C23.9303 24.5276 23.758 24.4817 23.5937 24.42C23.4115 24.5375 23.2275 24.6558 23.0409 24.7755C22.8511 24.8974 22.6207 25.0474 22.3577 25.2206V25.9141C22.5319 25.5725 22.9127 25.2666 23.2083 25.251C23.3356 25.2443 23.7776 25.6529 25.5994 25.5524C26.4835 25.5037 28.1914 24.5611 28.1646 23.4761C28.1485 22.8349 27.8136 22.3348 27.3712 21.9482ZM22.3573 23.6235V24.3298C22.5185 24.2222 22.6774 24.1164 22.8333 24.0133C22.5881 23.8427 22.4144 23.6833 22.3573 23.6235ZM26.7483 21.5048C26.5786 21.4043 26.4067 21.3163 26.2419 21.2386C25.3846 20.8368 24.4871 20.7631 24.2996 20.2206C24.1674 19.8379 24.5407 19.4839 25.4516 19.4972C26.3625 19.5106 27.2064 20.0532 27.4341 20.2474V18.5194C27.3671 18.5864 27.0733 18.9235 26.8045 18.8677C26.2888 18.7605 25.4114 18.6207 24.4068 18.6265C23.2548 18.6332 22.2635 19.5236 22.23 20.6492C22.1831 22.2232 23.4423 22.6519 24.1523 22.8997C24.2255 22.9251 24.309 22.9524 24.3996 22.9814C25.0805 22.5384 25.619 22.1973 25.9343 22.0156C26.1459 21.8937 26.4232 21.7186 26.7483 21.5048Z'
            fill='white'
          />
        </g>
      </g>
    </svg>
  );
}

import React from 'react';
import { TextInput, TextInputMask } from '@trussworks/react-uswds';

export function USWDSTextInput(props) {
  return <TextInput {...props} />;
}

export function USWDSTextInputMask(props) {
  return <TextInputMask {...props} />;
}
